import React, {useContext, useEffect, useState} from 'react';
import Hero from '../../components/Hero/Hero';
import Accordions from '../../components/AccordionGroup/AccordionGroup';
import '../../../styles/pages/Home/Home.css';
import AnchorLink from "../../components/AnchorLink/AnchorLink";
import {Link} from "react-router-dom";
import LanguageContext from "../../contexts/LanguageContext";
import PageLayout from "../../components/PageLayout/PageLayout";

const Home = () => {
    const { language } = useContext(LanguageContext);

    // Define states
    const [isLoading, setIsLoading] = useState(true); // set initial state for loading
    const [AccordionContent, setAccordionContent] = useState({});
    const [Heroes, setHeroes] = useState({});
    const [scrollY, setScrollY] = useState(window.scrollY);
    const [fadeOutLanding, setFadeOutLanding] = useState(false);
    const initialScreenHeight = window.innerHeight; // Obtain initial screen height

    const handleScroll = () => {
        setScrollY(window.scrollY);
    }

    useEffect(() => {
        const loadLocalizedData = async () => {
            try {
                const accContent = await import(`../../../db/${language}/pages/Home/AccordionContentHome.json`);
                const heroes = await import(`../../../db/${language}/pages/Home/Heroes.json`);

                setAccordionContent(accContent.default);
                setHeroes(heroes.default);

                setIsLoading(false); // set loading to false after data is fetched
            } catch(error) {
                console.error("Failed to load localized data: ", error);
                setIsLoading(false);
            }
        }

        loadLocalizedData().catch(console.log);
    }, [language]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (scrollY > initialScreenHeight * 0.75) {
            setFadeOutLanding(true);
        }

        if (scrollY === 0) {
            setFadeOutLanding(false);
        }
    }, [scrollY, initialScreenHeight]);

    if (!isLoading) {
        return (
            <PageLayout>
                <div className={"clip-overflow-x"}>
                    <div style={{
                        backgroundImage: `url(../../assets/pages/Home/starfield3.webp)`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                    }}
                         className={'stickyBox'}>
                        <div
                            className={'fullPageWrapper sticky-top z-1 bg-alpha-gradient-cream-dark-brown brand-box-shadow-indent'}>
                            <div className={`fadeEffect-fast ${fadeOutLanding ? 'fadeOut' : ''}`}>
                                <Hero content={Heroes.landing}/>
                                <AnchorLink bgClass={""} elementId="#section1" directionUp={false}/>
                            </div>
                        </div>
                    </div>
                    <div id="section1"
                         className={'fullPageWrapper flex-column justify-content-center align-items-center position-relative z-2 bg-alpha-brand-cream'}>
                        <Accordions AccordionContent={AccordionContent.group1.content} id={AccordionContent.group1.id}/>
                        <AnchorLink bgClass={""} elementId={`#section2`} directionUp={false}/>
                    </div>
                    <div id="section2" className={'position-relative z-3 bg-brand-background-star-1-vertical'}>
                        <div
                            className={'fullPageWrapper sticky-top z-4 bg-alpha-gradient-light-brown-dark-teal brand-box-shadow-indent-diffused'}>
                            <Hero content={Heroes.FeaturedProjectsHeader}/>
                            <AnchorLink bgClass={""} elementId={`#${Heroes.Project1.id}`} directionUp={false}/>
                        </div>
                        <div id={Heroes.Project1.id} className={'fullPageWrapper sticky-top z-5'}>
                            <Hero content={Heroes.Project1}/>
                            <AnchorLink bgClass={""} elementId={`#${Heroes.Project2.id}`} directionUp={false}/>
                        </div>
                        <div id={Heroes.Project2.id} className={'fullPageWrapper sticky-top z-6'}>
                            <Hero content={Heroes.Project2}/>
                            <AnchorLink bgClass={""} elementId={`#${Heroes.Project3.id}`} directionUp={false}/>
                        </div>
                        <div id={Heroes.Project3.id} className={'fullPageWrapper sticky-top z-7'}>
                            <Hero content={Heroes.Project3}/>
                            <AnchorLink bgClass={""} elementId="#contactAccordion" directionUp={false}/>
                        </div>
                        <div className={'fullPageWrapper sticky-top z-8'}>
                            <div
                                className={"d-flex flex-column justify-content-center align-items-center text-center bg-alpha-gradient-cream border-rounded shrink grow z-top"}>
                                <div className={"nav-item p-3"}>
                                    <Link className="nav-link"
                                          to={'/portfolio'}>{language === 'en' ? 'See more projects' : 'Voir plus de projets'}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageLayout>
        );
    }
}
export default Home;