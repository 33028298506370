import {Link} from "react-router-dom";
import '../../../styles/components/AccordionGroup/AccordionGroup.css';

const Tables = ({content, link}) => (
    <>
        {content.map((item, index) => (
            <div className={"col-10 col-sm-4 col-lg-4 col-xl-2 mb-5"} key={index}>
                <h5 className="h5">{item.title}</h5>
                <ul className="brand-box-shadow list-group list-group-flush">
                    {item.content.map((content, index) => (
                        <li className="bg-cream accordionTableItem" key={index}>
                            {content}
                        </li>
                    ))}
                </ul>
            </div>
        ))}
        <div className={'linkWrapper'}>
            {link && <Link className="linkButton" to={link.pageLink}>{link.linkText}</Link>}
        </div>
    </>
);

export default Tables;