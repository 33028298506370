import '../../../styles/components/AccordionGroup/AccordionGroup.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import '../../../styles/components/Contacts/Contacts.css';

const Contacts = () => (
        <div className={'accordionContentBox'}>
            <div className={'nav-item brand-box-shadow border-circle p-3 mx-5 my-2'}>
                <a href="https://www.linkedin.com/in/tylersmalleyfsd/" target="_blank" rel="noreferrer" aria-label="Link to LinkedIn profile">
                    <FontAwesomeIcon icon={faLinkedin} size={'3x'} className={'text-brand-cream'}/>
                </a>
            </div>
            <div className={'nav-item brand-box-shadow border-circle p-3 mx-5 my-2'}>
                <a href="mailto:tylerdsmalley@gmail.com" target="_blank" rel="noreferrer" aria-label="Email link">
                    <FontAwesomeIcon icon={faEnvelope} size={'3x'} className={'text-brand-cream'}/>
                </a>
            </div>
            <div className={'nav-item brand-box-shadow border-circle p-3 mx-5 my-2'}>
                <a href="https://github.com/TylerDSmalley" target="_blank" rel="noreferrer" aria-label="Link to GitHub profile">
                    <FontAwesomeIcon icon={faGithub} size={'3x'} className={'text-brand-cream'}/>
                </a>
            </div>
        </div>
);

export default Contacts;