import {Link} from "react-router-dom";
import '../../../styles/components/AccordionGroup/AccordionGroup.css';

const Text = ({content, link}) => (
    <>
        <div className={'accordionContentBox flex-column'}>
            {content.map((item, index) => (
                <div className={'textBox bg-gradient-cream text-brand-teal brand-box-shadow border-rounded p-3 my-2'} key={index}>
                    <blockquote className={''}>
                        {item.textQuote}
                    </blockquote>
                    {item.textCite && (
                        <footer>
                            <cite>{item.textCite}</cite>
                        </footer>
                    )}
                </div>
            ))}
        </div>
        <div className={'linkWrapper'}>
            {link && <Link className="linkButton" to={link.pageLink}>{link.linkText}</Link>}
        </div>
    </>
);

export default Text;