import Modal from 'react-bootstrap/Modal';
import "../../../styles/components/MyModal/MyModal.css"
import Carousel from "react-bootstrap/Carousel";
import React from "react";

const ModalImages = ({images}) => (
    <Carousel className={'brand-box-shadow my-carousel mb-4'}>
        {images.map((image, index) => (
            <Carousel.Item key={index}>
                <img className="modalImages d-block w-100" src={image.imgUrl}
                     alt={image.label}/>
            </Carousel.Item>
        ))}
    </Carousel>
);

const ModalLinks = ({links}) => (
    <div className="modal-links">
        {links.map((link, index) => (
            <a key={index} className="nav-item p-3 modal-link"
               href={link.linkUrl}
               target="_blank"
               rel="noopener noreferrer">
                {link.label}
            </a>
        ))}
    </div>
);

const ModalTexts = ({texts}) => (
    <div className="text-start mt-4">
        {texts.map((text, index) => (
            <p key={index} className="mb-4 text-indention modal-text"
               dangerouslySetInnerHTML={{__html: text}}></p>
        ))}
    </div>
);

const MyModal = ({show, handleClose, title, modalContent}) => {
    return (
        <Modal show={show} onHide={handleClose} size={"xl"} className={'my-modal'}>
            <Modal.Header closeButton className={'bg-gradient-medium-light-light-brown text-brand-cream pb-4 pt-4'}>
                <Modal.Title className={'text-brand-font display-3'}>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={'bg-gradient-cream'}>
                <div className="text-center pb-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <ModalImages images={modalContent.modalImages}/>
                            <ModalLinks links={modalContent.modalLinks}/>
                            <ModalTexts texts={modalContent.modalText}/>
                            <div className="imgBlock">
                                <img className={'modalFooter border-circle brand-box-shadow'}
                                     src={'/assets/logos/tsLogo.webp'}
                                     alt="Tyler Smalley Logo"/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
export default MyModal;