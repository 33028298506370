import React, { useState } from 'react';
import AccordionContext from '../contexts/AccordionGroupContext';


const AccordionProvider = ({ children }) => {
    // Initialize as an empty object
    const [accordionOpen, setAccordionOpen] = useState({});
    const contextValue = [accordionOpen, setAccordionOpen]; // <== Added to our context.

    return (
        <AccordionContext.Provider value={contextValue}>
            {children}
        </AccordionContext.Provider>
    );
};

export default AccordionProvider;