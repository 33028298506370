import {useContext, useEffect, useState} from "react";

import Footer from "../Footer/Footer";
import AnchorLink from "../AnchorLink/AnchorLink";
import Accordions from '../AccordionGroup/AccordionGroup';

import LanguageContext from "../../contexts/LanguageContext";
import PageTransition from "../PageTransition/PageTransition";

const PageLayout = ({children}) => {
    const {language} = useContext(LanguageContext);
    const [AccordionContent, setAccordionContent] = useState({});
    const [atBottom, setAtBottom] = useState(false);

    const handleScroll = () => {
        const scrollTop = window.scrollY;
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight

        if (windowHeight + scrollTop >= documentHeight - 1) {
            setAtBottom(true);
        } else {
            setAtBottom(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const loadLocalizedData = async () => {
            try {
                const accContent = await import(`../../../db/${language}/site/AccordionContentContact.json`);
                setAccordionContent(accContent.default);
            } catch (error) {
                console.error("Failed to load localized data: ", error);
            }
        }
        loadLocalizedData().catch(console.log);
    }, [language]);

    return (
        <>
            <PageTransition />
            {children}
            <div id="contactAccordion" className={'position-relative z-9 bg-brand-cream'}>
                {AccordionContent && AccordionContent.group1 &&
                    <Accordions AccordionContent={AccordionContent.group1.content}
                                id={AccordionContent.group1.id}/>}
            </div>
            <div className={'bg-gradient-medium-brown-light-brown position-relative z-10'}>
                <AnchorLink bgClass={""} elementId="#navTop" directionUp={true}/>
                <Footer/>
            </div>
            <div className={ atBottom ? null : 'bottom-blur'}></div>
        </>
    );
}

export default PageLayout;