import React from 'react';
import AccordionProvider from './AccordionProvider';
import NavMenuProvider from './NavMenuProvider';
import LanguageProvider from "./LanguageProvider";
import ThemeProvider from './ThemeProvider';

const GlobalProvider = ({ children }) => {
    return (
        <LanguageProvider>
            <ThemeProvider>
                <NavMenuProvider>
                    <AccordionProvider>
                        {children}
                    </AccordionProvider>
                </NavMenuProvider>
            </ThemeProvider>
        </LanguageProvider>
    );
};
export default GlobalProvider;