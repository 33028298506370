import React from 'react';
import {Link} from "react-router-dom";

import '../../../styles/components/TeaserTileGroup/TeaserTileGroup.css';

const TeaserTileGroup = ({content, link}) => {

    return (
        <div>
            <div className={'teaserTileGroupBox brand-box-shadow-indent p-5'}>
                {content.map((projectContent, index) => (
                    <div className='teaserTileBox bg-gradient-cream border-rounded' key={index}>
                        <h3 className='projectTitle p-2'>{projectContent.projectTitle}</h3>
                        <Link className="accordionImgLink" to="/portfolio">
                            <img className={'teaserTileImg'} src={projectContent.imagePath}
                                 alt={projectContent.projectTitle}/>
                        </Link>
                        <div className={'teaserDescription text-brand-teal p-3'}>{projectContent.description}</div>
                    </div>
                ))}
            </div>
            <div className={'linkWrapper'}>
                {link && <Link className="linkButton" to={link.pageLink}>{link.linkText}</Link>}
            </div>

        </div>
    );
};
export default TeaserTileGroup;