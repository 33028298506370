import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';

import NavMenuContext from '../../contexts/NavMenuContext';
import LanguageContext from '../../contexts/LanguageContext';

import { motion } from 'framer-motion';

import ThemeSwitcher from '../ThemeSwitcher/ThemeSwitcher';

import '../../../styles/components/NavMenu/NavMenu.css';

const NavMenu = () => {
    const { language, setLanguage } = useContext(LanguageContext);
    const { isOpen, setIsOpen } = useContext(NavMenuContext);

    const [navItems, setNavItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showThemeMenu, setShowThemeMenu] = useState(false);

    const toggleLanguage = () => {
        setLanguage(current => current === 'en' ? 'fr' : 'en');
    };

    const toggleMenu = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen, setIsOpen]);

    useEffect(() => {
        const loadLocalizedData = async () => {
            try {
                const navItemsModule = await import(`../../../db/${language}/components/NavMenu/NavLinks.json`);
                setNavItems(navItemsModule.default);
                setIsLoading(false);
            } catch (error) {
                console.error('Failed to load localized data: ', error);
                setIsLoading(false);
            }
        };

        loadLocalizedData();
    }, [language]);

    // Logic to close navMenu when user clicks outside the nav element
    const menuRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setIsOpen]);

    const variants = {
        hidden: { opacity: 0, y: -10 },
        visible: i => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: 0.3 + i * 0.2
            }
        })
    }

    const NavLink = ({ path, name, index }) => (
        <motion.li
            className="nav-item w-100"
            initial="hidden"
            variants={variants}
            animate={isOpen ? 'visible' : 'hidden'}
            custom={index}
        >
            <Link
                className="nav-link"
                to={path}
                onClick={() => setIsOpen(false)}
            >
                {name}
            </Link>
        </motion.li>
    );

    const themes = ['woodland-hues', 'midnight-velvet'];

    if (isLoading) {
        return null;
    }

    return (
        <nav className={`navbar ${isOpen ? 'showNav' : 'closed'}`} id="navTop" ref={menuRef}>
            <div className="navCircle">
                <button className={`burger ${isOpen ? 'is-active' : ''}`} id="open-button" onClick={toggleMenu} aria-label={isOpen ? "Close menu" : "Open menu"} type="button">
                    <div className="bar top"></div>
                    <div className="bar middle"></div>
                    <div className="bar bottom"></div>
                </button>
            </div>
            <div className="menuBox">
                <div className="backgroundImage"></div>
                <div className="menu-wrap">
                    {!showThemeMenu && (
                        <motion.ul
                            className="link-list d-flex flex-column justify-content-center align-items-center"
                            initial="hidden"
                            animate={isOpen ? "visible" : "hidden"}
                            variants={variants}
                        >
                            {navItems.map((item, index) =>
                                <NavLink key={index} path={item.path} name={item.name} index={index} />
                            )}
                            <motion.li
                                className={'horizontal-line my-1'}
                                variants={variants}
                                custom={(navItems.length - 1) + 1}
                            ></motion.li>
                            <motion.li
                                className="nav-item p-1"
                                variants={variants}
                                custom={(navItems.length - 1) + 2}
                            >
                                <button className="nav-link" onClick={toggleLanguage}>
                                    {language === 'en' ?
                                        <><strong>En</strong> | Fr</> :
                                        <>En | <strong>Fr</strong></>}
                                </button>
                            </motion.li>
                            <motion.li
                                className="nav-item p-1"
                                variants={variants}
                                custom={(navItems.length - 1) + 3}
                            >
                                <button className="nav-link" onClick={() => setShowThemeMenu(true)}>
                                    Themes
                                </button>
                            </motion.li>
                        </motion.ul>
                    )}
                    {showThemeMenu && (
                        <motion.ul
                            className="theme-list d-flex flex-column justify-content-center align-items-center"
                            initial="hidden"
                            animate="visible"
                            variants={variants}
                        >
                            {themes.map((theme, index) => (
                                <motion.li
                                    className="nav-item w-100"
                                    key={index}
                                    variants={variants}
                                >
                                    <ThemeSwitcher theme={theme} />
                                </motion.li>
                            ))}
                            <motion.li
                                className={'horizontal-line my-1'}
                                variants={variants}
                            ></motion.li>
                            <motion.li
                                className="nav-item p-1"
                                variants={variants}
                            >
                                <button className={"nav-link"} onClick={() => setShowThemeMenu(false)}>
                                    Back to Main Menu
                                </button>
                            </motion.li>
                        </motion.ul>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default NavMenu;
