import React, {useEffect, useRef, useContext, useState} from 'react';
import AccordionContext from '../../contexts/AccordionGroupContext';
import Text from '../Text/Text';
import Tables from '../Tables/Tables';
import TeaserTileGroup from '../TeaserTileGroup/TeaserTileGroup';
import Contacts from "../Contacts/Contacts";
import '../../../styles/components/AccordionGroup/AccordionGroup.css';

const componentMapping = {
    tables: Tables,
    text: Text,
    teaserTiles: TeaserTileGroup,
    contacts: Contacts
}

const ContentComponent = ({type, content, link}) => {
    const ComponentToRender = componentMapping[type];
    return ComponentToRender ? <ComponentToRender content={content} link={link} actionType={'link'}/> : null;
}

const Accordion = React.forwardRef(({id, title, content, isOpen, onToggle}, ref) => {
    const accordionClass = `accordion ${isOpen ? 'active' : ''}`;
    const accordionContentClass = `accordion-content-wrapper brand-box-shadow-indent ${isOpen ? 'active' : ''}`;
    return (
        <div className={accordionClass} ref={ref}>
            <div className="accordion-btn from-center">
                <div className="accordion-header" onClick={onToggle} role="button" tabIndex="0">
                    {title}
                </div>
            </div>
            <div className={accordionContentClass}>
                <div className="accordion-content row justify-content-center text-center">
                    {content}
                </div>
            </div>
        </div>
    );
});

const AccordionGroup = ({AccordionContent, id}) => {
    const [isAccordionOpen, setIsAccordionOpen] = useContext(AccordionContext);
    const [recentToggle, setRecentToggle] = useState(null);
    const AccordionsRef = useRef({});

    const toggleAccordion = (id) => {
        setRecentToggle(id);
        setIsAccordionOpen(currentOpenState => ({
            ...currentOpenState,
            [id]: !currentOpenState[id],
        }));
    };

    const scrollToAccordion = () => {
        if (recentToggle !== null && AccordionsRef.current[recentToggle]) {
            const element = AccordionsRef.current[recentToggle];
            setTimeout(() => {
                const elementRect = element.getBoundingClientRect();
                const absoluteElementTop = window.scrollY + elementRect.top;
                window.scrollTo({top: absoluteElementTop, behavior: "auto"});
                setRecentToggle(null);
            }, 500);  // replace this with the animation duration in milliseconds
        }
    }

    useEffect(scrollToAccordion, [recentToggle]);

    return (
        <div id={id} className={'accordionGroup w-100'}>
            {
                AccordionContent.map((accordion) =>
                    <Accordion
                        key={accordion.id}
                        id={accordion.id}
                        title={accordion.title}
                        link={accordion.link}
                        content={<ContentComponent {...accordion} />}
                        ref={el => AccordionsRef.current[accordion.id] = el}
                        isOpen={isAccordionOpen[accordion.id] === true}
                        onToggle={() => toggleAccordion(accordion.id)}
                    />)
            }
        </div>
    );
};
export default AccordionGroup;