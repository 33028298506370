// React imports
import React, {useEffect, useContext} from 'react';
import {BrowserRouter as Router, useLocation, useRoutes} from 'react-router-dom';

//3rd party libs
import { AnimatePresence } from "framer-motion";

// Vercel
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Analytics } from "@vercel/analytics/react"

// Contexts
import NavMenuContext from './scripts/contexts/NavMenuContext';
import GlobalProvider from './scripts/providers/GlobalProvider';

// Components
import NavMenu from './scripts/components/NavMenu/NavMenu';

// Pages
import Home from './scripts/pages/Home/Home';
import About from './scripts/pages/About/About';
import Experience from './scripts/pages/Experience/Experience';
import Portfolio from './scripts/pages/Portfolio/Portfolio';

// Styles
import './styles/site/App.css';

function useScrollToTop(pathname) {
    // Scroll to top when pathname changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
}

function App() {
    return (
        <Router>
            <GlobalProvider>
                <NavMenu/>
                <AppContents/>
                <SpeedInsights />
                <Analytics />
            </GlobalProvider>
        </Router>
    );
}

function AppContents() {
    const {isOpen: isMenuOpen} = useContext(NavMenuContext);
    const {pathname} = useLocation();

    const routes = useRoutes([
        {path: "/", element: <Home/>},
        {path: "/about", element: <About/>},
        {path: "/experience", element: <Experience/>},
        {path: "/portfolio", element: <Portfolio/>}
        // add more routes here...
    ]);

    useScrollToTop(pathname);
    if (!routes) return null;

    return (
        <div className={isMenuOpen ? 'backdropBlur' : ''}>
            <AnimatePresence mode="sync" initial={false}>
                {routes}
            </AnimatePresence>
        </div>
    );
}

export default App;