import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import MyModal from '../../components/MyModal/MyModal';
import '../../../styles/components/ImageCarousel/ImageCarousel.css';


//FIXME: extract this component later for reuse on other img elements
const LoadingImage = ({ className, src, alt }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const handleImageLoad = () => {
        setIsLoaded(true);
    };

    return (
        <img
            className={className}
            style={{ opacity: isLoaded ? 1 : 0 }}
            onLoad={handleImageLoad}
            src={src}
            alt={alt}
        />
    );
};

const ModalActionButton = ({ projectContent, handleClick }) => {
    return (
        <button
            className="accordionImgLink no-style-button"
            onClick={() => handleClick(projectContent)}
            tabIndex="0"
        >
            <LoadingImage
                className={'carouselImg'}
                src={projectContent.imagePath}
                alt={projectContent.projectTitle + ' info popup'}
            />
        </button>
    );
};

const PortfolioLinkButton = ({ projectContent }) => {
    return (
        <Link className="accordionImgLink" to="/portfolio">
            <img className={'carouselImg'} src={projectContent.imagePath}
                 alt={projectContent.projectTitle} />
        </Link>
    );
};

const PageLink = ({ link }) => {
    return (link && <Link className="linkButton" to={link.pageLink}>{link.linkText}</Link>);
};

const ImageCarousel = ({ content, link, actionType }) => {
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const projectId = params.get('modal');
        if (projectId) {
            const projectContent = content.find(project => project.projectId === projectId);
            if (projectContent) handleClick(projectContent);
        }
    }, [content]);

    const [show, setShow] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const handleClose = () => {
        setShow(false);
        setSelectedProject(null);
    };
    const handleClick = (project) => {
        setSelectedProject(project);
        setShow(true);
    };
    const getGroups = (arr, size) => {
        var groups = [];
        for (var i = 0; i < arr.length; i += size) {
            groups.push(arr.slice(i, i + size));
        }
        return groups;
    }

    const groups = getGroups(content, 4);

    return (
        <>
            {groups.map((group, i) => (
                <div className={'imageGroupBox p-5'} key={i}>
                    {group.map((projectContent) => (
                        <div className='singleImgBox' key={projectContent.projectId}>
                            <h3 className='projectTitle text-brand-teal'>{projectContent.projectTitle}</h3>
                            {actionType === 'modal' ? (
                                <ModalActionButton projectContent={projectContent} handleClick={handleClick} />
                            ) : (
                                <PortfolioLinkButton projectContent={projectContent} />
                            )}
                        </div>
                    ))}
                </div>
            ))}
            <div className={'linkWrapper'}>
                <PageLink link={link} />
            </div>
            {selectedProject &&
                <MyModal show={show} handleClose={handleClose} title={selectedProject.projectTitle} modalContent={selectedProject.modalContent} />
            }
        </>
    );
};
export default ImageCarousel;