import React, { useState } from 'react';
import NavMenuContext from '../contexts/NavMenuContext';

const NavMenuProvider = ({ children }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <NavMenuContext.Provider value={{ isOpen: isMenuOpen, setIsOpen: setIsMenuOpen }}>
            {children}
        </NavMenuContext.Provider>
    );
};

export default NavMenuProvider;