import React, { useContext, useState, useEffect } from 'react';
import LanguageContext from '../../contexts/LanguageContext';

import Showcase from "../../components/Showcase/Showcase";
import ImageCarousel from '../../components/ImageCarousel/ImageCarousel';

import '../../../styles/pages/Portfolio/Portfolio.css';
import PageLayout from "../../components/PageLayout/PageLayout";

const Portfolio = () => {

    const { language } = useContext(LanguageContext);
    const [isLoading, setIsLoading] = useState(true);
    const [showcaseContent, setShowcaseContent] = useState({});
    const [PortfolioContent, setPortfolioContent] = useState({});

    useEffect(() => {
        const loadLocalizedData = async () => {
            try {
                const showcaseModule = await import(`../../../db/${language}/pages/Portfolio/PortfolioShowcase.json`);
                const portfolioContentModule = await import(`../../../db/${language}/pages/Portfolio/PortfolioContent.json`);

                setShowcaseContent(showcaseModule.default);
                setPortfolioContent(portfolioContentModule.default);

                setIsLoading(false); // set loading to false after data is fetched
            } catch (error) {
                console.error("Failed to load localized data: ", error);
                setIsLoading(false);
            }
        };

        loadLocalizedData().catch(console.log);
    }, [language]);

    if (isLoading) {
        return null; // render nothing while loading
    }

    return (
        <PageLayout>
            <div className={"bg-brand-background-star-1"}>
                <Showcase bgClass="bg-gradient-dark-teal-medium-teal"
                          title={showcaseContent.title}
                          content={showcaseContent.content}
                          link={showcaseContent.link} imgUrl={showcaseContent.imgUrl}/>
                <div className={'fullPageWrapper overflow-hidden bg-alpha-brand-cream py-5'}>
                    <ImageCarousel content={PortfolioContent.content} link={PortfolioContent.link}
                                   actionType={'modal'}/>
                </div>
            </div>
        </PageLayout>
    );
};

export default Portfolio;