import React, { useState, useEffect } from 'react';
import LanguageContext from "../contexts/LanguageContext";

const LanguageProvider = ({ children }) => {
    // Initialize state with value from local storage or default to 'en'
    const [language, setLanguage] = useState(
        localStorage.getItem('TS_LANG_PREF') || 'en'
    );

    // Effect to set local storage whenever language changes
    useEffect(() => {
        localStorage.setItem('TS_LANG_PREF', language);
    }, [language]);

    // Effect to update the 'lang' attribute of the <html> tag
    useEffect(() => {
        document.documentElement.lang = language;
    }, [language]);

    return (
        <LanguageContext.Provider value={{ language, setLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

export default LanguageProvider;