import React from 'react';
import "../../../styles/components/Showcase/Showcase.css"
import {Link} from "react-router-dom";

const Showcase = ({bgClass, title, content, link, imgUrl}) => {
    return (
        <div className={`showcaseFullWidth brand-box-shadow ${bgClass}`}>
            <div className={`showcaseWrapper p-5 ${!imgUrl ? 'noImg' : ''}`}>
                {imgUrl && (
                    <div className="imgBlock">
                        <Link
                            className="logoLink border-circle"
                            to={'/'}>
                            <img className={'border-circle'} src={imgUrl} alt="Showcase"/>
                        </Link>
                    </div>
                )}
                <div className="textBlock">
                    {title && <h1 className={"pageTitle text-brand-cream pageTitleFontSize m-3"}>{title}</h1>}
                    {content && <p className={"textBody text-brand-cream justify-text"}>{content}</p>}
                    {link.linkUrl && <a className={"linkButton"} href={link.linkUrl}>{link.linkLabel}</a>}
                </div>
            </div>
        </div>
    );
};

export default Showcase;