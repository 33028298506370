import '../../../styles/components/Footer/Footer.css';

function Footer() {
    return (
        <footer id={'footer'} className={"footerWrapper"}>
            &copy; 2023 Ad Astro
        </footer>
    );
}

export default Footer;