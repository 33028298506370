// PageTransition.js
import { motion, useIsPresent } from 'framer-motion';

const PageTransition = () => {
    const isPresent = useIsPresent();

    return (
        <>
            <motion.div
                initial={{scaleY: 1}}
                animate={{scaleY: 0, transition: {duration: 0.4, ease: "easeInOut", when: "beforeChildren"}}}
                exit={{scaleY: 1, transition: {duration: 0.4, ease: "easeInOut", when: "afterChildren"}}}
                style={{originY: isPresent ? 0 : 1}}
                className="privacy-screen bg-alpha-gradient-dark-brown-dark-medium"
            />
            <motion.div
                initial={{scaleY: 1}}
                animate={{scaleY: 0, transition: {duration: 0.4, ease: "easeInOut", when: "beforeChildren"}}}
                exit={{scaleY: 1, transition: {duration: 0.4, ease: "easeInOut", when: "afterChildren"}}}
                style={{originY: isPresent ? 1 : 0}}
                className="privacy-screen bg-alpha-gradient-medium-teal-medium-light brand-box-shadow-indent-diffused"
            />
        </>
    );
};

export default PageTransition;