import React from "react";
import '../../../../styles/components/Card/SubCard/SubCard.css';

const SubCard = ({ cardClass, bgClass, title, date, position, location, skills, content, children }) => (
    <div className={`${cardClass} ${bgClass} brand-box-shadow p-3 mb-4`}>
        <div className={'cardBody'}>
            <div className={'cardHeaderBox'}>
                <h4 className={'subCardHeader text-brand-teal fw-bold mb-0'}>{title}</h4>
                {date && <div className="text-muted">{date}</div>}
            </div>
            {position && <div className="text-brand-teal fw-bold">{position}</div>}
            {location && <div className="fst-italic mb-2 text-muted">{location}</div>}
            {skills && <div className={'justify-text'}>SKILLS: <span className={'fst-italic'}>{skills.join(', ')}</span></div>}
            {content &&
                <ul className={"mt-4"}>
                    {content.map((item, itemIndex) => <li className={'cardListItem'} key={itemIndex}>{item}</li>)}
                </ul>
            }
            <div className={'justify-text'}>
                {children}
            </div>
        </div>
    </div>
);

export default SubCard;