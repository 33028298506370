import React, { useContext } from 'react';
import ThemeContext from '../../contexts/ThemeContext';

const ThemeSwitcher = ({ theme }) => {
    const { setTheme } = useContext(ThemeContext);

    const handleClick = () => {
        setTheme(theme);
    };

    return <button className="nav-link w-100" onClick={handleClick}>{theme}</button>;
};

export default ThemeSwitcher;
