import React from "react";
import '../../../styles/components/Card/Card.css';

const Card = ({ bgClass, header, children }) => (
    //fixme: perhaps put brand-box-shadow-indent here
    <div className={`fullWidth ${bgClass}`}>
        <div className="contentWrapper-column-50 mt-5">
            <h3 className={'subjectHeader headerFontSize text-center'}>{header}</h3>
            <div className={'subjectBody text-brand-teal'}>
                {children}
            </div>
        </div>
    </div>
);

export default Card;