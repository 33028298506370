import React, {useState} from 'react';
import '../../../styles/components/ImageSlider/ImageSlider.css';

const ImageSlider = ({sliderContent, lang}) => {
    const [disablePointerEvents, setDisablePointerEvents] = useState(false);
    const [animateImages, setAnimateImages] = useState(false);
    const [hoverStates, setHoverStates] = useState({
        bottomLeft: {isHover: false},
        topLeft: {isHover: false},
        center: {isHover: false},
        topRight: {isHover: false},
        bottomRight: {isHover: false},
    });
    const [animationEnd, setAnimationEnd] = useState(false);

    const toggleHoverState = (corner, isEnter) => {
        if (clickedCorner !== null) return; // if an image is clicked, ignore hover state updates
        setHoverStates(prevState => ({
            ...prevState,
            [corner]: {...prevState[corner], isHover: isEnter},
        }));
    };

    const [clickedCorner, setClickedCorner] = useState(null);

    const toggleClickState = (corner) => {
        setClickedCorner(prev => (prev === corner ? null : corner));
    };

    const engageAnimation = () => {
        setAnimateImages(prev => !prev);
        setDisablePointerEvents(prev => !prev);
        setAnimationEnd(false);
    }

    const animationEnded = (event) => {
        if (event.nativeEvent.animationName === "animation-topLeft") {
            setAnimationEnd(true);
        }
    };

    const getTopLeftClassName = () =>
        `item topLeft brand-box-shadow 
    ${animateImages ? 'topLeftAnimate' : 'topLeftAnimateReturn'}
    ${hoverStates.bottomLeft.isHover || hoverStates.bottomRight.isHover ? "topLeftActive" : ""} 
    ${clickedCorner === "topLeft" ? "fullGrid" : ""}
    ${disablePointerEvents ? "disable-pointer-events" : ""}`;

    const getTopRightClassName = () =>
        `item topRight brand-box-shadow ${animateImages ? 'topRightAnimate' : 'topRightAnimateReturn'} 
    ${hoverStates.bottomLeft.isHover || hoverStates.topLeft.isHover ? 'topRightHoverActive' : ''} 
    ${clickedCorner === "topRight" ? 'fullGrid' : ''}
    ${disablePointerEvents ? "disable-pointer-events" : ""}`;

    const getBottomRightClassName = () =>
        `item bottomRight brand-box-shadow
    ${animateImages ? 'bottomRightAnimate' : 'bottomRightAnimateReturn'} 
    ${hoverStates.topLeft.isHover || hoverStates.topRight.isHover ? 'bottomRightHoverActive' : ''} 
    ${clickedCorner === "bottomRight" ? 'fullGrid' : ''}
    ${clickedCorner !== null ? 'noTransform' : ''}
    ${disablePointerEvents ? "disable-pointer-events" : ""}`;

    const getBottomLeftClassName = () =>
        `item bottomLeft brand-box-shadow ${animateImages ? 'bottomLeftAnimate' : 'bottomLeftAnimateReturn'} 
    ${hoverStates.topRight.isHover || hoverStates.bottomRight.isHover ? 'bottomLeftHoverActive' : ''} 
    ${clickedCorner === "bottomLeft" ? 'fullGrid' : ''}
    ${clickedCorner !== null ? 'noTransform' : ''}
    ${disablePointerEvents ? "disable-pointer-events" : ""}`;

    const getCenterClassName = () =>
        `item center brand-box-shadow d-flex justify-content-center align-items-center ${animateImages ? 'centerAnimate' : 'centerAnimateReturn'} 
    ${hoverStates.bottomLeft.isHover ? 'centerActive' : ''} ${hoverStates.bottomLeft.isHover ? 'bottomLeftEffect' : ''}
    ${hoverStates.topLeft.isHover ? 'topLeftEffect' : ''} ${hoverStates.topRight.isHover ? 'topRightEffect' : ''}
    ${hoverStates.bottomRight.isHover ? 'bottomRightEffect' : ''}
    ${disablePointerEvents ? "disable-pointer-events" : ""}`;

    return (
        <div className="grid-container">
            <div className={`text-bg-wrapper brand-box-shadow-diffused bg-gradient-cream border-rounded`}>
                <div className="text-container text-brand-teal p-3">
                    <p>{sliderContent.sliderText}</p>
                    <button className={'nav-item z-10 p-3'}
                            onClick={engageAnimation}>{lang === 'en' ? 'Return to photos' : 'Retour aux photos'}</button>
                </div>
            </div>
            <div className={`grid border-rounded ${animationEnd ? 'z-negative' : ''}`}>
                <img
                    className={getTopLeftClassName()}
                    src={sliderContent.images.topLeftImg}
                    alt={sliderContent.sliderHeader}
                    loading="lazy"
                    onMouseEnter={() => toggleHoverState('topLeft', true)}
                    onMouseLeave={() => toggleHoverState('topLeft', false)}
                    onClick={() => toggleClickState('topLeft')}
                    onAnimationEnd={animationEnded}>
                </img>
                <img
                    className={getTopRightClassName()}
                    src={sliderContent.images.topRightImg}
                    alt={sliderContent.sliderHeader}
                    loading="lazy"
                    onMouseEnter={() => toggleHoverState('topRight', true)}
                    onMouseLeave={() => toggleHoverState('topRight', false)}
                    onClick={() => toggleClickState('topRight')}>
                </img>
                <img
                    className={getBottomRightClassName()}
                    src={sliderContent.images.bottomRightImg}
                    alt={sliderContent.sliderHeader}
                    loading="lazy"
                    onMouseEnter={() => toggleHoverState('bottomRight', true)}
                    onMouseLeave={() => toggleHoverState('bottomRight', false)}
                    onClick={() => toggleClickState('bottomRight')}>
                </img>
                <img
                    className={getCenterClassName()}
                    src={sliderContent.images.centerImg}
                    onClick={engageAnimation}
                    alt={sliderContent.sliderHeader}
                    loading="lazy"
                    role="button"
                    tabIndex="0">
                </img>
                <img
                    className={getBottomLeftClassName()}
                    src={sliderContent.images.bottomLeftImg}
                    alt={sliderContent.sliderHeader}
                    loading="lazy"
                    onMouseEnter={() => toggleHoverState('bottomLeft', true)}
                    onMouseLeave={() => toggleHoverState('bottomLeft', false)}
                    onClick={() => toggleClickState('bottomLeft')}>
                </img>
            </div>
        </div>
    );
};

export default ImageSlider;