import React, { useEffect, useState, useContext } from 'react';
import Showcase from "../../components/Showcase/Showcase";
import Card from "../../components/Card/Card";
import SubCard from "../../components/Card/SubCard/SubCard"
import LanguageContext from '../../contexts/LanguageContext';

import '../../../styles/pages/Experience/Experience.css';
import PageLayout from "../../components/PageLayout/PageLayout";

const Experience = () => {
    const { language } = useContext(LanguageContext);

    // Define states
    const [isLoading, setIsLoading] = useState(true);
    const [showcaseContent, setShowcaseContent] = useState({});
    const [skills, setSkills] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [education, setEducation] = useState([]);
    const [summary, setSummary] = useState({});

    useEffect(() => {
        const loadLocalizedData = async () => {
            try {
                const showcaseModule = await import(`../../../db/${language}/pages/Experience/ExperienceShowcase.json`);
                const skillsModule = await import(`../../../db/${language}/pages/Experience/Skillset.json`);
                const jobsModule = await import(`../../../db/${language}/pages/Experience/Jobs.json`);
                const educationModule = await import(`../../../db/${language}/pages/Experience/Education.json`);
                const summaryModule = await import(`../../../db/${language}/pages/Experience/Summary.json`);

                setShowcaseContent(showcaseModule.default);
                setSkills(skillsModule.default);
                setJobs(jobsModule.default);
                setEducation(educationModule.default);
                setSummary(summaryModule.default);

                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.error("Failed to load localized data: ", error);
            }
        }

        loadLocalizedData().catch(console.log);
    }, [language]);

    if (!isLoading) {
        return (
            <PageLayout>
                {/*Hero*/}
                <Showcase bgClass="bg-gradient-dark-teal-medium-teal" title={showcaseContent.title}
                          content={showcaseContent.content} link={showcaseContent.link}
                          imgUrl={showcaseContent.imgUrl}/>

                <div className={'bg-brand-background-star-2-vertical'}>
                    {/* Summary Card */}
                    <Card bgClass="bg-alpha-gradient-cream" header={summary.title}>
                        <SubCard bgClass="bg-brand-cream border-rounded">
                            {summary.content}
                        </SubCard>
                    </Card>

                    {/* Skillset Card */}
                    <Card bgClass="bg-alpha-gradient-medium-brown-light-brown brand-box-shadow-indent"
                          header={language === 'en' ? 'Skillset' : 'Compétences'}>
                        {skills.map(skill => (
                            <SubCard cardClass="subCard-grid-3 border-rounded" bgClass="bg-brand-cream"
                                     title={skill.title}
                                     content={skill.content} key={skill.title}/>
                        ))}
                    </Card>

                    {/*Professional Experience Card */}
                    <Card bgClass="bg-alpha-gradient-cream brand-box-shadow-indent" header={language === 'en' ? 'Professional Experience' : 'Expériences professionnelles'}>
                        {jobs.map((job) => job.content.map((content, subIndex) =>
                            <SubCard
                                cardClass="subCard-row-full border-rounded"
                                bgClass="bg-brand-cream"
                                title={job.title}
                                date={content.date}
                                position={content.position}
                                location={content.location}
                                skills={content.jobSkills}
                                content={content.bullets}
                                key={`${job.title}-${subIndex}`}
                            />
                        ))}
                    </Card>

                    {/*Educational Experience Card */}
                    <Card bgClass="bg-alpha-gradient-medium-teal-medium-light brand-box-shadow-indent"
                          header={language === 'en' ? 'Education' : 'Éducation'}>
                        {education.map((edu) => edu.content.map((content, subIndex) =>
                            <SubCard
                                cardClass="subCard-row-full border-rounded "
                                bgClass="bg-brand-cream"
                                title={edu.title}
                                date={content.date}
                                position={content.position}
                                location={content.location}
                                content={content.bullets}
                                key={`${edu.title}-${subIndex}`}
                            />
                        ))}
                        <div className={'d-flex w-100 flex-column justify-content-center align-items-center'}>
                            <a className={"linkButton mb-3"} href={language === 'en' ? '/assets/docs/Tyler-Smalley-CV_EN_Sanitized.pdf' : '/assets/docs/Tyler-Smalley-CV_FR_Sanitized.pdf'} target="_blank"
                               rel="noopener noreferrer">{language === 'en' ? 'Take resumé to go' : 'Télécharger le CV'}</a>
                        </div>
                    </Card>
                </div>
            </PageLayout>
        );
    }
};
export default Experience;