import React, { useContext, useState, useEffect } from 'react';
import LanguageContext from '../../contexts/LanguageContext';

import Showcase from "../../components/Showcase/Showcase";
import ImageSlider from "../../components/ImageSlider/ImageSlider";

import '../../../styles/pages/About/About.css';
import "../../../styles/components/ImageSlider/ImageSlider.css"
import PageLayout from "../../components/PageLayout/PageLayout";

const About = () => {
    const { language } = useContext(LanguageContext);
    const [isLoading, setIsLoading] = useState(true);
    const [showcaseContent, setShowcaseContent] = useState({});
    const [imageSliderContent, setImageSliderContent] = useState([]);

    const alphaBgClasses = [
        'bg-alpha-gradient-cream',
        'bg-alpha-gradient-medium-light-light-brown',
        'bg-alpha-gradient-cream',
        'bg-alpha-gradient-medium-teal-medium-light',
        'bg-alpha-gradient-cream',
        'bg-alpha-gradient-dark-brown-medium-brown',
    ];

    useEffect(() => {
        const loadLocalizedData = async () => {
            try {
                const showcaseModule = await import(`../../../db/${language}/pages/About/AboutShowcase.json`);
                const imageSliderModule = await import(`../../../db/${language}/pages/About/ImgSliderContent.json`);

                setShowcaseContent(showcaseModule.default);
                setImageSliderContent(imageSliderModule.default);
                setIsLoading(false);
            } catch (error) {
                console.error("Failed to load localized data: ", error);
                setIsLoading(false);
            }
        }

        loadLocalizedData().catch(console.log);
    }, [language]);

    if (isLoading) {
        return null;
    }

    return (
        <PageLayout>
            <div className={"hideOverflow"}>
                <Showcase bgClass="bg-gradient-dark-teal-medium-teal" title={showcaseContent.title}
                          content={showcaseContent.content}
                          link={showcaseContent.link} imgUrl={showcaseContent.imgUrl}/>
                <div className={'position-relative bg-brand-background-star-3-vertical'}>
                    {imageSliderContent.map((content, index) =>
                        <div
                            className={`sticky-top fullPageWrapper brand-box-shadow-indent text-center ${alphaBgClasses[index % alphaBgClasses.length]}`}>
                            <h2 className={'text-brand-font w-100 sliderHeader text-brand-cream my-5 h-auto'}>{content.sliderHeader}</h2>
                            <ImageSlider
                                key={index}
                                sliderContent={content}
                                lang={language}
                            />
                        </div>
                    )}
                </div>
            </div>
        </PageLayout>
    );
};

export default About;