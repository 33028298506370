import {HashLink as Link} from "react-router-hash-link";
import ArrowUpImage from '../../../assets/img/site/arrow-up-337-svgrepo-com.svg';
import "../../../styles/components/AnchorLink/AnchorLink.css"

function AnchorLink({bgClass, elementId, directionUp}) {
    let direction = "";
    let bounceAnimation = "bounceDown"
    if(directionUp === true) {
        direction = "arrowUp";
        bounceAnimation = "bounceUp";
    }

    return (
        <Link smooth to={elementId} className= {bgClass + " arrowBox link-block-4 w-inline-block m-0 p-0"} >
            <img src={ArrowUpImage}
                 role="button" tabIndex="0"
                 loading="lazy"

                 alt="arrow"
                 className={`lookArrow fadeEffect ${direction} ${bounceAnimation} mt-3`}
            />
        </Link>
    );
}

export default AnchorLink;